@font-face {
  font-family: 'din';
  src: url(./font/dinbold.eot);
  src: url(./font/dinbold.eot?#iefix) format('embedded-opentype'),
    url(./font/dinbold.woff2) format('woff2'),
    url(./font/dinbold.woff) format('woff'),
    url(./font/dinbold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'din';
  src: url(./font/dinmed.eot);
  src: url(./font/dinmed.eot?#iefix) format('embedded-opentype'),
    url(./font/dinmed.woff2) format('woff2'),
    url(./font/dinmed.woff) format('woff'),
    url(./font/dinmed.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'din';
  src: url(./font/din.eot);
  src: url(./font/din.eot?#iefix) format('embedded-opentype'),
    url(./font/din.woff2) format('woff2'), url(./font/din.woff) format('woff'),
    url(./font/din.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
